@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');

.book_container {
    margin-left: 20px;
    margin-top: 20px;
}

.book_title {
    font-size: $l-size;
    font-family: 'Baloo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.book_sub_text {
    margin-top: 0px;
    font-size: $xs-size;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.book_dropdown_field {
    width: 30%;
}

.book_calendar_container {
    background-color: white;
    margin-top: 15px; 
    font-size: $xxs-size;
    margin-right: 20px;
    margin-left: 0px;
    padding: 15px;
    border-radius: 4px;
    position: relative;
    .rbc-date-cell {
        font-size: $xs-size;
        margin-top: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
}

.book_pop_up {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 25px;
    z-index: 10;
    border-radius: 8px;
    min-width: 550px;
}

.book_pop_up_admins {
    min-width: 300px;
}

.book_pop_up_background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 5;
}

.book_pop_up_close {
    position: absolute;
    top: 0px;
    right: -6px;
    font-size: $m-size;
    user-select: none;
}

.book_pop_up_close:hover {
    cursor: pointer;
}

.book_pop_up_title {
    font-size: $l-size;
    font-family: 'Baloo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.book_pop_up_description {
    font-size: $xs-size;
}

.book_pop_up_number_field {
    margin-top: 30px;
    font-family: 'open sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.book_pop_up_button {
    background-color: $dark-color;
    padding: 10px;
    margin: 10px;
    color: white;
    border-radius: 6px;
    margin-left: 0;
}

.book_pop_up_button:hover {
    background-color: $darkest-color;
    cursor: pointer;
}

.book_pop_up_button:disabled {
    background-color: $darkest-color;
}

.book_pop_up_button:disabled:hover {
    background-color: $darkest-color;
    cursor: default;
}

.book_pop_up_booking_schedule_flex {
    display: flex;
}

.book_pop_up_booking_select_date {
    padding-right: 20px;
    h3 {
        font-size: $s-size;
        font-weight: normal;
    }
    p {
        font-weight: bold;
    }
}

.book_pop_up_booking_display_schedules {
    padding: 0px 20px 20px 20px;
    border-left: 1px solid black;
}

.book_pop_up_booking_display_schedules h2 {
    margin-bottom: 7px;
}

.book_pop_up_booking_text {
    font-size: $xxs-size;
}

.book_pop_up h2 {
    font-size: $m-size;
    margin-top: 5px;
}

.book_pop_up p {
    font-size: $xs-size;
    font-family: 'open sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 7px;
}

.bookingloading {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $xl-size;
    color: white;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .period1 {
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
  }
  
  .period2 {
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
  }
  
  .period3 {
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }

  .hidden {
    display: none;
  }