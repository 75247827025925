@import '../../../variables.scss';

.field-input-button-combo {
  display: flex;
}

.field-input.combo-input {
  border-radius: $xxxs-size 0 0 $xxxs-size;
}

.field-input.combo-button {
  width: unset;
  border-left: unset;
  border-radius: 0 $xxxs-size $xxxs-size 0;
  background-color: $dark-color;
  color: whitesmoke;
  font-weight: 600;
}

.field-input.combo-button:hover:enabled {
  opacity: 50%;
}

.field-input.combo-button:disabled {
  filter: brightness(1.5) grayscale(1);
}
