@import '../../variables.scss';

.form-container {
  max-width: 80rem;
  background-color: $light-color;
  margin: 0 auto;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}

.form-title {
  font-size: $xl-size;
  background-color: $darkest-color;
  color: $light-color;
  padding: $s-size 0;
  text-align: center;
  width: 100%;
}

.form-subtitle {
  font-size: $s-size;
  font-weight: 700;
  width: 100%;
  margin-top: $xs-size;
}

.field-container {
  padding: 0 $s-size;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}

.form-submit-button {
  width: 100%;
  margin-bottom: $xs-size;
  font-size: $m-size;
  background-color: $dark-color;
  color: white;
  font-weight: 900;
  border-radius: $xxxxs-size;
  height: 8rem;
}

.form-submit-button:hover:enabled {
  opacity: 0.75;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}

.loading {
  opacity: 0.75;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}

.lds-ellipsis div {
  position: absolute;
  top: 3.3rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 0.8rem;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 0.8rem;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 3.2rem;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 5.6rem;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.4rem, 0);
  }
}
