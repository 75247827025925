@import '../../../variables.scss';

.orderupload-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(172, 172, 172, .5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hidden {
    display: none !important;
  }
}

.order-message{
  text-align: left;
  margin-bottom: 2rem;
  font-size: $xs-size;
  font-weight: 600;
  color: $darkest-color;
}
.order-header{
  text-align: center;
  margin-bottom: 2rem;
  font-size: $l-size;
  font-weight: 600;
  color: $darkest-color;
}
.order-group{
  width: 400px;
  height: 100%;
  background-color: $light-color;
  color: $darkest-color;
  padding: 5rem;
}

.order-carts{
  width: 100%;
  height: 100%;
  background-color: $main-color;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  font-size: 6rem;
  position: relative;
}

.no-carts{
  font-size: 10rem;
  opacity: .5;
}

.order-submit-button{
  width: 100%;
  margin-bottom: $xs-size;
  font-size: $m-size;
  background-color: $dark-color;
  color: white;
  font-weight: 900;
  border-radius: $xxxxs-size;
  padding: 10px;
}

.order-submit-button:hover:enabled{
  opacity: 0.75;
}

.site-information-header {
  font-size: $s-size;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 30px;
}

.site-information-text {
  font-size: $xs-size;
  margin: 10px 0;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.period1 {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

.period2 {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.period3 {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.orders-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.orders-header-bar {
  height: auto;
  display: flex;
  background-color: $dark-color;
  font-size: $s-size;
  margin: 2px 18px;
  margin-top: 10px;
  color: $light-color;
  text-align: center;
}

.orders-header-title {
  width: 30%;
  padding: 20px 10px;
}

.orders-header-verified {
  width: 20%;
  border-left: 2px solid #4A617E;
  padding: 20px 10px;
}

.orders-header-survey {
  width: 20%;
  border-left: 2px solid #4A617E;
  padding: 20px 10px;
}

.orders-header-submitted {
  width: 20%;
  border-left: 2px solid #4A617E;
  padding: 20px 10px;
}

.orders-header-buttons {
  width: 10%;
  border-left: 2px solid #4A617E;
  padding: 20px 10px;
}

.all-orders-height-setup {
  height: calc(100% - 8px - 58px);
}

.orders-height-setup {
  height: calc(100% - 68px);
}

.all-orders {
  height: 100%;
  overflow-y: auto;
}

.tab-bar {
  font-size: $xs-size;
  background-color: $darkest-color;
  color: $light-color;
  width: 100%;
  box-shadow: inset 0 -4px 4px #404c58;
  -webkit-box-shadow: inset 0 -4px 4px #404c58;
  height: 58px;
}

.tab {
  padding: 20px 25px;
  display: inline-flex;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

.tab:hover {
  background-color: $main-color;
  color: black;
  cursor: pointer;
}

.tab-selected {
  background-color: $main-color;
  color: black;
}

.cartCreationError {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(172, 172, 172, .5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartCreationErrorDialogBox {
  width: 50%;
  background-color: $light-color;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.cartCreationErrorMessage {
  font-size: $s-size;
  flex: 0 0 100%;
  width: 100%;
}

.cartCreationErrorButtons {
  background-color: $dark-color;
  color: white;
  padding: 10px;
  margin-top: 30px;
  margin-right: 10px;
  border-radius: 3px;
}

.cartCreationErrorButtons:hover {
  cursor: pointer;
}

