@import '../../../variables.scss';

.grey-out{
  color: rgb(198, 198, 198);
}

.grey-out option{
  display: none;
}
.site-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-settings-message{
  text-align: center;
  margin-bottom: 2rem; 
  font-size: $xs-size;
  font-weight: 600;
  color: $darkest-color;
}
.site-settings-header{
  text-align: center;
  margin-bottom: 2rem;
  font-size: $l-size;
  font-weight: 600;
  color: $darkest-color;
}
.site-settings-group{
  width: 400px;
  height: 100%;
  background-color: $light-color;
  color: $darkest-color;
  padding: 5rem;
}

.site-settings-carts{
  width: 100%;
  height: 100%;
  background-color: $main-color;
  color: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  font-size: 6rem;
}

.no-carts{
  font-size: 10rem;
}

.site-submit-button{
  width: 100%;
  margin-top: 4rem;
  margin-bottom: $xs-size;
  font-size: $m-size;
  background-color: $dark-color;
  color: white;
  font-weight: 900;
  border-radius: $xxxxs-size;
  height: 4rem;
}

.site-settings-submit-button:hover:enabled{
  opacity: 0.75;
}  
