.order-form {
    position: absolute;
    top: 20px;
    background-color: #f7f7ff;
    bottom: 20px;
    overflow-y: scroll;
    z-index: 3;
    left: 20px;
    right: 20px;
    border-radius: 9px;
    padding: 15px;

    .field {
        width: 100%;
        padding: 0 10px;
    }
}

.close-button {
    position: fixed;
    top: 35px;
    right: 20px;
    color: #495867;
}

.close-button:hover {
    cursor: pointer;
}

.order-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.col-1 {
    grid-column-start: 1;
    grid-column-end: 2;
}

.col-2 {
    grid-column-start: 2;
    grid-column-end: 3;
}

.col-3 {
    grid-column-start: 3;
    grid-column-end: 4;
}

.col-12 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.section-title {
    align-self: end;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-top: 20px;
}

.order-submit-button {
    width: 100%;
    margin-bottom: 1.6rem;
    font-size: 2.6rem;
    background-color: #577399;
    color: white;
    font-weight: 900;
    border-radius: 0.4rem;
    margin-top: 15px;
}

.order-submit-button:hover {
    cursor: pointer;
}
