@import '../../../../variables.scss';

.submit-header-bar {
    height: auto;
    display: flex;
    background-color: $dark-color;
    font-size: $s-size;
    margin: 2px 18px;
    margin-top: 10px;
    color: $light-color;
    text-align: center;
}

.submit-header-title {
    width: 25%;
    padding: 20px 10px;
}

.submit-header-cart {
    width: 25%;
    padding: 20px 10px;
    border-left: 2px solid #4A617E;
}

.submit-header-status {
    width: 25%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.submit-header-shipping-account {
    width: 25%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.submit-container {
    height: auto;
    display: flex;
    background-color: $light-color;
    font-size: $xxs-size;
    margin: 2px 18px;
}

.submit-title {
    width: 25%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
}

.submit-cart {
    width: 25%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-left: 2px solid #E2E7EC;
}

.submit-status {
    width: 25%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-left: 2px solid #E2E7EC;
}

.submit-shipping-account {
    width: 25%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-left: 2px solid #E2E7EC;
}

.all-submit-height-setup {
    height: calc(100% - 73px - 75px - 58px);
}

.all-submit-height-setup-no-submit {
    height: calc(100% - 75px - 58px);
}

.all-submit {
    height: 100%;
    overflow-y: auto;
}

.error-color {
    background-color: #ff9abc;
}

.success-color {
    background-color: #93e493;
}

.submit-carts-button {
    position: absolute;
    bottom: 10px;
    background-color: #577399;
    padding: 15px;
    color: white;
    width: calc(100% - 36px);
    margin: 0 18px;
    border-radius: 3px;
    font-size: 2rem;
}

.submit-carts-button:hover {
    cursor: pointer;
}