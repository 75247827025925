@import '../../../../variables.scss';

.fulfillment-container {
    height: auto;
    display: flex;
    background-color: $light-color;
    font-size: 1.5rem;
    margin: 2px 18px;
    padding: 10px;
    :hover {
        cursor: pointer;
    }
}

.fulfillment-order-id {
    width: 40%;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-fulfilled {
    width: 40%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-buttons {
    width: 20%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.fulfillment-order-id-header {
    width: 40%;
    padding: 20px 10px;
}

.order-fulfilled-header {
    width: 40%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.fulfilled-buttons-header {
    width: 20%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.order-buttons span {
    text-align: center;
}

.order-info {
    font-size: $xxs-size;
}

.validation-errors {
    margin: 0 18px;
    background-color: #ff9abc;
    padding: 15px 10px;
}

.validation-errors:not(:last-child) {
    border-bottom: 2px solid #f9829d;
}

.hidden {
    display: none;
}

.inline {
    display: inline;
}

.error-color {
    background-color: #ff9abc;
}

.success-color {
    background-color: #93e493;
}

.clickable:hover {
    cursor: pointer;
}