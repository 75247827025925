@import '../../variables.scss';

.global-error-section {
  font-size: 2.6rem;
  background-color: #fe5f55;
  text-align: center;
  color: whitesmoke;
  padding: 0.8rem;
  position: sticky;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
}

.global-error-x {
  position: absolute;
  top: 0;
  right: $xxxs-size;
}
