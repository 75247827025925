@import '../../../../variables.scss';

.products-header-bar {
    height: auto;
    display: flex;
    background-color: $dark-color;
    font-size: $s-size;
    margin: 2px 18px;
    margin-top: 10px;
    color: $light-color;
    text-align: center;
}

.products-header-sku {
    width: 33.33%;
    padding: 20px 10px;
}

.products-header-quantity {
    width: 33.33%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.products-header-validity {
    width: 33.33%;
    border-left: 2px solid #4A617E;
    padding: 20px 10px;
}

.product-container {
    height: auto;
    display: flex;
    background-color: $light-color;
    font-size: $xxs-size;
    margin: 2px 18px;
}

.product-sku {
    width: 33.33%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
}

.product-quantity {
    width: 33.33%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-left: 2px solid #E2E7EC;
}

.product-valid {
    width: 33.33%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    border-left: 2px solid #E2E7EC;
}

.all-products-height-setup {
    height: calc(100% - 75px - 58px);
}

.all-products {
    height: 100%;
    overflow-y: auto;
}

.error-color {
    background-color: #ff9abc;
}

.success-color {
    background-color: #93e493;
}

.fulfillment-error-text {
    font-size: 1.5rem;
    padding: 18px;
    background-color: white;
    margin: 10px;
}

.fulfillment-verification-header {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: $l-size;
  font-weight: 600;
  padding: 15px;
}