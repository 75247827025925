@keyframes fade-in{
    from {background-color: rgba(0,0,0,0);}
    to {background-color: rgba(0,0,0,.6);}
}

.background {
    position: absolute;
    z-index: 9999;
    background-color: rgba(0,0,0,.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-duration: 500ms;
}