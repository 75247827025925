.order-payment-terms-container {
    padding: 4rem;
    max-width: 75rem;
    margin: 2rem auto 0;
}

.order-payment-terms-header {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3.2rem;
    font-weight: 600;
    color: #495867;
}

.submit-button{
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
    font-size: 2.6rem;
    background-color: #495867;
    color: white;
    font-weight: 900;
    border-radius: 0.4rem;
    height: 4rem;
    cursor: pointer;
}

.submit-button:hover:enabled{
    opacity: 0.75;
}  