@import '../../../variables.scss';

.removeable-list {
  margin: $xxs-size 0;
  list-style: square outside none;
  font-size: $xs-size;
}

.removeable-list-item {
  padding-left: $xs-size;
}

.removeable-list-item a:hover {
  opacity: 50%;
}

.removeable-list-item-remove {
  margin-left: $l-size;
  font-size: $xxs-size;
  cursor: pointer;
}

.removeable-list-item-remove:hover {
  opacity: 50%;
}
