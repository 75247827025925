@import '../../../variables.scss';

.field {
  width: 47.5%;
}

@media (max-width: $phone-size) {
  .field {
    width: 100%;
  }
}

.field-full {
  width: 100%;
}

.field-input {
  width: 100%;
  font-size: $xs-size;
  padding: .5rem 1rem;
  border: 0.3rem solid $dark-color;
  border-radius: $xxxs-size;
  color: $darkest-color;
  background-color: white;
}
