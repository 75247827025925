@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import '../../variables.scss';

$menu-width: 6rem;
$expanded-menu-width: 26rem;
$menu-transition: 0.1s;

.fa-2x {
  font-size: 2em;
}

.fa {
  position: relative;
  display: table-cell;
  width: $menu-width;
  height: $xxl-size;
  text-align: center;
  vertical-align: middle;
  font-size: $m-size;
}

.main-menu:hover,nav.main-menu.expanded {
  width: $expanded-menu-width;
  overflow: visible;
}

.main-menu {
  background: $darkest-color;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: $menu-width;
  overflow: hidden;
  -webkit-transition: width $menu-transition linear;
  transition: width $menu-transition linear;
  -webkit-transform: translateZ(0) scale(1,1);
  transform: translateZ(0) scale(1,1);
  z-index: 9999;
}

.main-menu .home {
  margin-bottom: 15rem;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: $expanded-menu-width;
}

.main-menu li > a, .main-menu li > .nav-link {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: white;
  font-family: arial;
  font-size: $s-size;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1,1);
  transform: translateZ(0) scale(1,1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 100%;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: $menu-width;
  height: $xl-size;
  text-align: center;
  vertical-align: middle;
  font-size: $s-size;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 20rem;
  font-family: sans-serif;
  font-weight: 600;
}

.main-menu .nav-text.subnav {
  padding-left: $xs-size;
  font-size: $s-size;
  font-weight: normal;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover, a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul, nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover>a,nav.main-menu li.active>a,.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus,.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,.dashboard-page nav.dashboard-menu ul li.active a, .main-menu li:hover>.nav-link {
  color: $off-black;
  background-color: #5fa2db;
}

.menu-area {
  float: left;
  width: 6rem;
  height: 100%;
  transition: width $menu-transition linear;
}

.global-container {
  display: flex;
}

.content-area {
  width: 100%;
  background-color: $main-color;
}

.opacity {
  background-color: black;
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0%;
  transition: opacity 0.25s linear;
}

.nav-link.active {
  color: $highlight-color-1 !important;
}

.nav-svg-icon {
  width: 6rem;
  padding: 5px 14px;
  fill: white;
  stroke: white;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.nav-svg-icon-active {
  fill: $highlight-color-1;
  stroke: $highlight-color-1;
}

.nav:hover .nav-svg-icon {
  fill: $off-black;
  stroke: $off-black;
}

.nav-caret {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 10px;
  color: white;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.nav:hover .nav-caret {
  color: $off-black;
  cursor: pointer;
}

.nav-link:hover {
  cursor: pointer;
}

.nav-sub-menu {
  background-color: #394654;
}

.nav-caret-active {
  color: $highlight-color-1;
}