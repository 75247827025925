@import '../../../variables.scss';

.nav-button {
    background-color: $light-color;
    padding: 10px;
    font-size: $xs-size;
    position: absolute;
    right: 10px;
    border-radius: 8px;
    border: 0.2rem solid #577399;
}

.nav-button:hover {
    cursor: pointer;
}

.nav-button-icon {
    display: inline !important;
    margin-left: 25px;
    font-size: 15px !important;
}

.nav-button-menu {
    background-color: $light-color;
    padding: 5px;
    font-size: $xxs-size;
    z-index: 1000;
    border: 0.2rem solid #577399;
    border-radius: 8px;
    margin-top: 2px;
}

.nav-button-menu-item {
    border-bottom: 1px solid black;
    padding: 15px 5px 15px 5px;
}

.nav-button-menu-item:hover {
    cursor: pointer;
}

.nav-button-menu-item:last-child {
    border: none;
}