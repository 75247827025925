@import '../../variables.scss';

.submitted-img {
  width: 100%;
}

.form-subheader {
  width: 100%;
  font-size: x-large;
  text-align: center;
  padding: 1rem;
  color: $darkest-color;
  font-weight: bold;
}
