@import '../../../variables.scss';

.services-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-group{
  z-index: 1;
  flex-wrap: nowrap;
  text-align: center;
}

button.service-btn{
  background: $dark-color;
  padding: 0.5rem;
  width: 37rem;
  height: 15rem;
  margin: 1rem;
  line-height: 15rem;
  color: $light-color;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: left;
  padding-left: 3rem;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  text-shadow: 2px 2px 3px $dark-color;
  box-shadow: 3px 3px 4px #455d7edd;
}

button.service-btn span {
  z-index: 1;
  position: relative;
}

button.service-btn:hover{
  background: #6d8fbe;
  cursor: pointer;
}

button.service-btn:hover .requests-icon {
  font-size: 20rem !important;
  right: 12rem;
  top: -1rem;
}

.productButton{
  width: 100%;
  margin: 1rem;
  color: $light-color;
  background: $darkest-color;
  padding: 0.5rem;
  font-weight: bold;
  font-size: $xs-size;
}

.productButton:hover{
  opacity: 0.5;
}

.note {
  width: 100%;
}

.requests-icon {
  position: absolute !important;
  font-size: 17rem !important;
  opacity: 0.2;
  top: 0rem;
  right: 10rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}