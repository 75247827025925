@import '../../../../variables.scss';

.order-container {
    height: auto;
    display: flex;
    background-color: $light-color;
    font-size: $xxs-size;
    margin: 2px 18px;
    :hover {
        cursor: pointer;
    }
}

.order-title {
    width: 30%;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-verified {
    width: 20%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-survey {
    width: 20%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-submitted {
    width: 20%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-buttons {
    width: 10%;
    border-left: 2px solid #E2E7EC;
    padding: 2px 10px;
    display: flex;
    align-items: center;
}

.order-buttons span {
    text-align: center;
}

.order-info {
    font-size: $xxs-size;
}

.validation-errors {
    margin: 0 18px;
    background-color: #ff9abc;
    padding: 15px 10px;
}

.validation-errors:not(:last-child) {
    border-bottom: 2px solid #f9829d;
}

.hidden {
    display: none;
}

.inline {
    display: inline;
}

.error-color {
    background-color: #ff9abc;
}

.success-color {
    background-color: #93e493;
}

.clickable:hover {
    cursor: pointer;
}