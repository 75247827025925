@import '../../../../variables.scss';
 
.optional-tag {
  font-style: oblique;
  color: $darkest-color;
}

.field-label {
  width: 100%;
  font-size: $xs-size;
  margin: $xxs-size 0 0.2rem 0;
}
