@import '../../variables.scss';

.home-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-message {
  text-align: center;
  margin-bottom: 2rem;
  font-size: $xs-size;
  font-weight: 600;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.home-header {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: $xl-size;
  font-weight: 600;
  font-family: 'Baloo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.home-group {
  width: 700px;
  height: 270px;
  background-color: $dark-color;
  color: white;
  z-index: 1;
  padding: 4rem 6rem 5rem 19rem;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
}

.home-group div {
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 3px $dark-color;
}

.home-group a {
  color: $highlight-color-1;
}

.home-penguin {
  width: 140px;
  position: absolute;
  bottom: -40px;
  left: 30px;
  opacity: .3;
}