.penguinName {
  position: absolute;
  z-index: 10000;
  left: calc(50% - 20rem);
  top: calc(50% - 10rem);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.child {
  justify-content: center;
  align-items: center;
  height: 20rem;
  width: 40rem;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  text-align: center;
}
.title {
  font-weight: bold;
  font-size: 20pt;
  margin-top: 30px;
}

.penguinNameInput {
  border: 1px solid black;
  border-radius: 4px;
  font-size: 15pt;
  margin-top: 15px;
  margin-bottom: 5px;
}

.submitBtn {
  background: #eca31b;
  padding: 1.3rem;
  margin: 3px;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  font-size: 11pt;
  font-weight: 600;
}

.submitBtn:hover {
  background: #eca31bce;
  cursor: pointer;
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.error {
  color: red;
  font-size: 12px;
}
  
.penguinNameResult {
  margin: 10px;
  font-size: 17pt;
}