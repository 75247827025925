@import '../../variables.scss';

.bg-div {
  position: absolute;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.25;
  top: 0;
  width: 100vw;
}

.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-group {
  z-index: 1;
}

#login-button {
  font-size: $xl-size;
  background-color: #57739950;
  padding: 1rem 4rem;
  opacity: 1;
  font-weight: 600;
  color: white;
  border: 3px white solid;
  cursor: pointer;
  z-index: 1;
  width: fit-content;
  margin: auto;
}

#login-button:hover {
  color: black;
  background-color: #577399;
  border: 3px black solid;
}

#login-message {
  text-align: center;
  margin-bottom: 2rem;
  font-size: $l-size;
  font-weight: 600;
}
