/* VARIABLES */
/* Colors */
$darkest-color: #495867;
$dark-color: #577399;
$main-color: #bdd5ea;
$light-color: #f7f7ff;
$alert-color: #fe5f55;
$off-black: rgba(0, 0, 0, 0.850);
$highlight-color-1: #b7e0fc;

/* Sizes */
$xxl-size: 4.4rem;
$xl-size: 3.8rem;
$l-size: 3.2rem;
$m-size: 2.6rem;
$s-size: 2rem;
$xs-size: 1.6rem;
$xxs-size: 1.2rem;
$xxxs-size: 0.8rem;
$xxxxs-size: 0.4rem;

/* Breakpoints */
$phone-size: 769px;
