@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-color; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link, :hover, :visited, :active {
  text-decoration: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark-color;
  font-style: oblique;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $dark-color;
  font-style: oblique;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $dark-color;
  font-style: oblique;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

.container {
  max-width: 102.4rem;
  width: 100%;
  position: relative;
}

.construction {
  display: flex;
  justify-content: center;
}