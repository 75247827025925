@import '../../../variables.scss';

.file-drop {
  width: 100%;
}

.dropzone-content {
  margin-top: $xxs-size;
  background-color: $main-color;
  outline: 0.3rem dashed $darkest-color;
  outline-offset: -1rem;
  font-size: $s-size;
  padding: 6rem 2rem;
  text-align: center;
}

.dropzone-content.dragging {
  opacity: 0.75;
}